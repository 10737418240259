import './helpers/postDate';
import './helpers/smooth-scroll'
import { orderForm } from "./modules/form";

function main() {
    orderForm();
}

if (document.documentElement.clientWidth < 480) {
  window.addEventListener('scroll',
    function () {
      return setTimeout(main, 1000);
    }, {
      once: true
    });
} else {
  main();
}
